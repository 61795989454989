import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Button, Modal, Icon, Popup } from "semantic-ui-react";
import "./Modal.css";
import ContactForm from "./ContactForm";

function exampleReducer(state, action) {
  switch (action.type) {
    case "OPEN_MODAL":
      return { open: true, dimmer: action.dimmer };
    case "CLOSE_MODAL":
      return { open: false };
    default:
      throw new Error();
  }
}

function ContactModal(props) {
  const [state, dispatch] = React.useReducer(exampleReducer, {
    open: false,
    dimmer: undefined,
  });
  const { open, dimmer } = state;
  const otherpaths = ["/services", "/buy", "/projects", "/gallery", "/about"];

  return (
    <div>
      <Route path="/" exact>
        <Button.Group>
          <Popup
            size="large"
            hoverable
            content="This feature is under development.
            Use the email option for now."
            trigger={
              <Button size="huge" animated="fade" color="blue">
                <Button.Content visible>Chat</Button.Content>
                <Button.Content hidden>
                  <Icon name="chat" />
                </Button.Content>
              </Button>
            }
          />
          <Button.Or />
          <Button
            size="massive"
            animated="fade"
            color="black"
            onClick={() => dispatch({ type: "OPEN_MODAL", dimmer: "blurring" })}
          >
            <Button.Content visible>Email Us</Button.Content>
            <Button.Content hidden>
              <Icon name="mail" />
            </Button.Content>
          </Button>
        </Button.Group>
      </Route>
      <Route path={otherpaths} exact>
        <Button.Group>
          <Popup
            size="large"
            hoverable
            content="This feature is under development.
             Use the email option for now."
            trigger={
              <Button size="huge" animated="fade" color="blue">
                <Button.Content visible>Chat</Button.Content>
                <Button.Content hidden>
                  <Icon name="chat" />
                </Button.Content>
              </Button>
            }
          />
          <Button.Or />
          <Button
            size="huge"
            animated="fade"
            color="black"
            onClick={() => dispatch({ type: "OPEN_MODAL", dimmer: "blurring" })}
          >
            <Button.Content visible>Email Us</Button.Content>
            <Button.Content hidden>
              <Icon name="mail" />
            </Button.Content>
          </Button>
        </Button.Group>
      </Route>

      <Modal
        dimmer={dimmer}
        open={open}
        onClose={() => dispatch({ type: "CLOSE_MODAL" })}
      >
        <Modal.Header>Contact Us</Modal.Header>
        <Modal.Content>
          If you would like us to custom make a design for you, please contact
          us by filling in the form below and we will get back to you as soon as
          possible. <br /> <br />
          Located in Pakenham, Victoria – we ship Australia wide.
          <ContactForm />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => dispatch({ type: "CLOSE_MODAL" })}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

export default ContactModal;
