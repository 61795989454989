import React from "react";
import emailjs from "emailjs-com";
import { Component } from "react";
import {
  Button,
  Checkbox,
  Dimmer,
  Form,
  Input,
  Loader,
  TextArea,
} from "semantic-ui-react";

export default class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      TNC: false,
      promos: true,
      loading: false,
    };
  }
  onToggle = () => {
    const TNC = !this.state.TNC;
    this.setState({ TNC });
  };
  onTogglePromos = () => {
    const promos = !this.state.promos;
    this.setState({ promos });
  };

  loadingbtn = () => {
    if (this.state.TNC !== false) {
      return this.setState({ loading: true });
    } else {
      return;
    }
  };
  sendEmail = (e, props) => {
    e.preventDefault();
    this.setState({ loading: true });
    if (this.state.TNC !== false) {
      return (
        emailjs
          /* EMAILJS data here */
          .sendForm("test", "testTemp", e.target, "user_EPYkKnHwljQTCbJkzO7YD")
          .then(
            (result) => {
              this.setState({ loading: false });
              alert("Email sent successfully!");
              e.target.reset();
            },
            (error) => {
              this.setState({ loading: false });
              alert("Email send failed... :( I had one job...");
            }
          )
      );
    } else {
      this.setState({ loading: false });
      return alert(
        "You need to accept the Terms and Conditions before proceeding."
      );
    }
  };

  render() {
    return (
      <Form onSubmit={this.sendEmail}>
        <Form.Group widths="equal">
          <Form.Field
            id="firstName"
            control={Input}
            label="First name"
            name="firstName"
            placeholder="First name"
            required
          />
          <Form.Field
            id="lastName"
            name="lastName"
            control={Input}
            label="Last name"
            placeholder="Last name"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            id="Email"
            control={Input}
            label="Email"
            name="email"
            placeholder="joe@schmoe.com"
            required
          />
          <Form.Field
            id="Phone"
            control={Input}
            label="Phone"
            name="phone"
            placeholder="0469 420 689"
            required
          />
        </Form.Group>
        <Form.Field
          id="Message"
          control={TextArea}
          label="Message"
          name="message"
          placeholder="Message"
          required
        />
        <Form.Group widths="equal">
          <Form.Field>
            <Checkbox
              id="Accept_TnCs"
              label="I agree to the Terms and Conditions"
              required
              control={Checkbox}
              name="TNC"
              value={this.state.TNC}
              checked={this.state.TNC}
              onChange={this.onToggle}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              id="Accept_Promos"
              label="Send me occasional updates and promotions"
              defaultChecked
              control={Checkbox}
              name="promos"
              value={this.state.promos}
              checked={this.state.promos}
              onChange={this.onTogglePromos}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field
          id="Submit"
          control={Button}
          type="submit"
          value="submit"
          positive
          onClick={this.loadingbtn}
          content={"Submit"}
          loading={this.state.loading}
          basic
        />
      </Form>
    );
  }
}
