import React from "react";
import { Button, Image } from "semantic-ui-react";
import "./CartItem.css";

export default class CartItem extends React.Component {
  constructor(props) {
    super(props);

    this.handleUpdateCartQty = this.handleUpdateCartQty.bind(this);
    this.handleRemoveFromCart = this.handleRemoveFromCart.bind(this);
  }

  handleUpdateCartQty(lineItemId, newQuantity) {
    this.props.onUpdateCartQty(lineItemId, newQuantity);
  }
  handleRemoveFromCart(lineItemId) {
    this.props.onRemoveFromCart(lineItemId);
  }

  render() {
    const { item } = this.props;

    return (
      <div className="cartitem">
        <Image
          size="small"
          rounded
          className="cart-item__image"
          src={item.media.source}
          alt={item.name}
        />
        <div className="cart-item__details">
          <h4 className="cart-item__details-name">{item.name}</h4>
          <div className="cart-item__details-price">
            {item.line_total.formatted_with_symbol}
          </div>
          <div className="cartitemdetailsqty">
            <div className="qtyadjust">
              <Button
                type="button"
                onClick={() =>
                  this.handleUpdateCartQty(item.id, item.quantity - 1)
                }
                title="Reduce quantity"
              >
                -
              </Button>
              <p>{item.quantity}</p>
              <Button
                type="button"
                onClick={() =>
                  this.handleUpdateCartQty(item.id, item.quantity + 1)
                }
                title="Increase quantity"
              >
                +
              </Button>
            </div>
          </div>
        </div>
        <Button
          negative
          type="button"
          onClick={() => this.handleRemoveFromCart(item.id)}
        >
          Remove
        </Button>
      </div>
    );
  }
}
