import React from "react";
import { Button, Icon, Image } from "semantic-ui-react";
import "./ProductItem.css";
import MediaQuery from "react-responsive";
import { stripHtml } from "string-strip-html";

export default class ProductItem extends React.Component {
  constructor(props) {
    super(props);

    
    this.handleAddToCart = this.handleAddToCart.bind(this);
  }
  handleAddToCart() {
    this.props.addToCart(this.props.product.id, 1);
  }
  render() {
    const { product } = this.props;
    const { result } = stripHtml(product.description);

    return (
      <div className="productcard">
        <Image
          rounded
          className="productimage"
          src={product.media.source}
          alt={product.name}
        />
        <div className="productinfo">
          <h4 className="productname">{product.name}</h4>
          <p className="productdescription">
            {/* product description stripped of html tags */}
            {result}
          </p>
          <div className="productdetails">
            <p className="productprice">
              {product.price.formatted_with_symbol}
            </p>
            <MediaQuery minWidth="400px">
              <Button
                name="Add to cart"
                className="productbtn productbtnlg"
                onClick={this.handleAddToCart}
              >
                <Icon name="cart plus" /> Add to Cart
              </Button>
            </MediaQuery>
            <MediaQuery maxWidth="399px">
              <Button
                name="Add to cart"
                className="productbtn productbtnsm"
                onClick={this.handleAddToCart}
              >
                <Icon name="cart plus" />
              </Button>
            </MediaQuery>
          </div>
        </div>
      </div>
    );
  }
}
