import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Dimmer, Image, Loader } from "semantic-ui-react";
import "./About.css";
import workshop from "./images/eibworkshops.jpg";

export const About = (props) => (
  <div className="aboutwrapper">
    <div className="aboutrow aboutheaderwrap">
      <h2>About Us</h2>
      <hr />
      <p>
        Two blokes, two workshops, one goal... to create unique, high quality,
        custom items that you need, at an affordable price!
      </p>
    </div>
    <div className="aboutrow">
      <Image
        className="aboutimage"
        label={{
          as: "a",
          color: "blue",
          content: "Sean's workshop, Pakenham Victoria",
          icon: "wrench",
          ribbon: true,
        }}
        rounded
        size="huge"
        src={workshop}
      ></Image>
      <div className="textwrap">
        <p>
          Everything in Between is run from Sean’s garage in Pakenham, and Rob’s
          garage in Hill End.
        </p>
        <p>
          Everything in Between is run by a couple of mates who love using our
          hands to custom make items to suit your ideas and needs. We utilize a
          number of tools in our creations, from CNC machines, laser etching, 3D
          printing, lathes, and other woodworking and metalworking tools.
        </p>
        <p>
          If you can dream it we can make it! We look forward to working with
          you to create your masterpiece!
        </p>
      </div>
    </div>
  </div>
);
