import React from "react";
import "./Home.css";
import ContactModal from "./Modal";

export const Home = (props) => (
  <div className="wrap">
    <div className="bdrop">
      <h2 className="landingheading">Your vision is our mission</h2>
      <p className="landingtext">
        Let Rob and Sean turn your vision into reality using their skills and
        expertise in wood working, 3D printing, CNC machining, laser etching and
        so much more!
      </p>
      <ContactModal />
    </div>
  </div>
);
