import React, { Component } from "react";
import { Button, Embed, Image } from "semantic-ui-react";
import "./Player.css";

export default class Player extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ids: [
        {
          id: "iCBvfW08jlo",
          active: true,
        },
        {
          id: "qvOcCQXZVg0",
          active: true,
        },
        {
          id: "YXNC3GKmjgk",
          active: false,
        },
        {
          id: "_4re-2ZNBSg",
          active: false,
        },
        {
          id: "_4re-2ZNBSg",
          active: false,
        },
        {
          id: "_4re-2ZNBSg",
          active: false,
        },
        {
          id: "_4re-2ZNBSg",
          active: false,
        },
        {
          id: "_4re-2ZNBSg",
          active: false,
        },
        {
          id: "_4re-2ZNBSg",
          active: false,
        },
        {
          id: "_4re-2ZNBSg",
          active: false,
        },
        {
          id: "_4re-2ZNBSg",
          active: false,
        },
      ],
    };
  }

  firstActiveId = () => {
    for (var i = 0; i < this.state.ids.length; i++) {
      if (this.state.ids[i].active) {
        return this.state.ids[i].id;
      }
    }
  };

  render() {
    return (
      <div className="carouselwrap">
        <div className="activevid">
          <Embed
            active
            autoplay={true}
            aspectRatio="16:9"
            hd={true}
            id={this.firstActiveId(this.state.ids)}
            iframe={{
              allowFullScreen: true,
              style: {
                padding: 0,
              },
            }}
            placeholder={
              "http://img.youtube.com/vi/" +
              this.firstActiveId(this.state.ids) +
              "/hqdefault.jpg"
            }
            source="youtube"
          />
        </div>
        {/*Image carousel */}
        <div className="viewport">
          {this.state.ids.map((i) => (
            <div className="thumb">
              <Image
                className="carouselitem"
                onClick={() => {
                  this.setState((prevState) => ({
                    ids: prevState.ids.map((ob) =>
                      ob.id !== i.id
                        ? { ...ob, active: false }
                        : { ...ob, active: true }
                    ),
                  }));
                }}
                src={"http://img.youtube.com/vi/" + i.id + "/hqdefault.jpg"}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
