import React from "react";
import { Embed, Image, List } from "semantic-ui-react";
import Player from "./Player";
import "./Services.css";

export default class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ids: [
        {
          id: "iCBvfW08jlo",
          active: true,
        },
        {
          id: "qvOcCQXZVg0",
          active: false,
        },
        {
          id: "YXNC3GKmjgk",
          active: false,
        },
      ],
    };
  }

  /*changeActiveField = (id, active) => {
    this.setState({
      ids: this.state.ids.map((obj) =>
        obj.id === id ? { ...obj, active: false } : obj
      ),
    });
  };*/

  render() {
    return (
      <div className="serviceswrap">
        <div className="servicesbdrop">
          <div className="primarywrap">
            <Player />

            <List size="big" className="servicesList">
              <List.Item>
                <List.Icon size="big" name="cog" />
                <List.Content>
                  <List.Header as="a">3D Printing</List.Header>
                  <List.Description>
                    Print your design using our 3d-printer.
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon size="big" name="cog" />
                <List.Content>
                  <List.Header as="a">CNC Machining</List.Header>
                  <List.Description>
                    Print your design using our CNC machienery.
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon size="big" name="cog" />
                <List.Content>
                  <List.Header as="a">Personalised Designs</List.Header>
                  <List.Description>
                    Design your idea to suite your needs.
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon size="big" name="cog" />
                <List.Content>
                  <List.Header as="a">Laser Etching</List.Header>
                  <List.Description>
                    Elegant designs etched onto wood.
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon size="big" name="cog" />
                <List.Content>
                  <List.Header as="a">Wood Working</List.Header>
                  <List.Description>
                    Build custom designed indoor and outdoor wooden signage.
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          </div>
        </div>
      </div>
    );
  }
}
