import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import "./Cart.css";
import CartItem from "./CartItem";
import MediaQuery from "react-responsive";

export default class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.handleUpdateCartQty = this.handleUpdateCartQty.bind(this);
    this.handleRemoveFromCart = this.handleRemoveFromCart.bind(this);
    this.handleEmptyCart = this.handleEmptyCart.bind(this);
  }
  handleUpdateCartQty(lineItemId, newQuantity) {
    this.props.onUpdateCartQty(lineItemId, newQuantity);
  }
  handleRemoveFromCart(lineItemId) {
    this.props.onRemoveFromCart(lineItemId);
  }
  handleEmptyCart() {
    this.props.onEmptyCart();
  }

  cart = this.props.cart;
  renderEmptyCart() {
    const { cart } = this.props;
    if (cart.total_unique_items > 0) {
      return;
    }

    return (
      <p className="cart__none">
        You have no items in your shopping cart, start adding some!
      </p>
    );
  }
  renderCart() {
    const { cart } = this.props;
    if (cart.total_unique_items === 0) {
      return;
    }

    return (
      <>
        <div className="cart">
          {this.cart.line_items.map((lineItem) => (
            <CartItem
              item={lineItem}
              key={lineItem.id}
              onUpdateCartQty={this.handleUpdateCartQty}
              onRemoveFromCart={this.handleRemoveFromCart}
              className="cart__inner"
            />
          ))}
        </div>

        <div className="cart__total">
          <p className="cart__total-title">Subtotal:</p>
          <p className="cart__total-price">
            {this.cart.subtotal.formatted_with_symbol}
          </p>
          <Button
            negative
            className="cart__btn-empty"
            onClick={this.handleEmptyCart}
          >
            Empty cart
          </Button>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="cartbg">
        <MediaQuery maxWidth="1319px">
          <div className="cartwrap cardwrapsm">
            <div className="cartheading">
              <h4>Your Shopping Cart</h4>
              <Link className="checkoutbutton" to="/checkout">
                <Icon name="credit card alternative" /> CheckOut
              </Link>
            </div>
            {this.renderEmptyCart()}
            {this.renderCart()}
          </div>
        </MediaQuery>
        <MediaQuery minWidth="1320px">
          <div className="cartwrap cardwraplg">
            <div className="cartheading">
              <h4>Your Shopping Cart</h4>

              <Link className="checkoutbutton" to="/checkout">
                <Icon name="credit card alternative" /> CheckOut
              </Link>
            </div>
            {this.renderEmptyCart()}
            {this.renderCart()}
          </div>
        </MediaQuery>
      </div>
    );
  }
}
