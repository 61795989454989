import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Button, Header, Icon, Image, Loader, Modal } from "semantic-ui-react";
import "./Gallery.css";

const classes = [
  "gallerycardsm",
  "gallerycardmd",
  "gallerycardlg",
  "gallerycardhuge",
];

export default class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [
        { file: "bella.jpg", rclass: classes[1], fs: false },
        { file: "bellavue.jpg", rclass: classes[0], fs: false },

        { file: "grumpygrove.jpg", rclass: classes[1], fs: false },
        { file: "weddingsignoak.jpg", rclass: classes[1], fs: false },
        { file: "wallcogs.jpg", rclass: classes[2], fs: false },
        { file: "Unicorn.png", rclass: classes[1], fs: false },
        { file: "streetsign.jpg", rclass: classes[3], fs: false },
        { file: "spotled.jpg", rclass: classes[0], fs: false },
        { file: "spothalo.jpg", rclass: classes[0], fs: false },
        { file: "onbothspot.jpg", rclass: classes[0], fs: false },
        { file: "halohaloon.jpg", rclass: classes[1], fs: false },
        { file: "doorraw.jpg", rclass: classes[3], fs: false },
        { file: "doorfinished.jpg", rclass: classes[3], fs: false },
        { file: "doorinstalled.jpg", rclass: classes[2], fs: false },

        { file: "bothcars.jpg", rclass: classes[0], fs: false },
        { file: "fusiongearsketch.png", rclass: classes[0], fs: false },
        { file: "fusiongear.png", rclass: classes[0], fs: false },
        { file: "nameengrave.jpg", rclass: classes[2], fs: false },
        { file: "roomdoors.jpg", rclass: classes[0], fs: false },
        { file: "earls.jpg", rclass: classes[0], fs: false },
      ],
    };
  }
  firstActiveId = () => {
    for (var i = 0; i < this.state.images.length; i++) {
      if (this.state.images[i].fs) {
        return this.state.images[i].file;
      }
    }
  };
  render() {
    return (
      <div className="gallerywrap">
        <div className="gallerycontainer">
          <div className="galleryheaderwrap">
            <h2>Gallery</h2>
            <hr />
            <p>Our past projects.</p>
          </div>
          <div className="imgwrap">
            {this.state.images.map((i) => (
              <div className={"gallerycard " + i.rclass}>
                <img
                  className="galleryimg"
                  src={"/gallery/" + i.file}
                  onClick={() => {
                    this.setState((prevState) => ({
                      images: prevState.images.map((ob) =>
                        ob.file === i.file
                          ? { ...ob, fs: true }
                          : { ...ob, fs: false }
                      ),
                    }));
                  }}
                  alt=""
                />
                <Modal className="gallerymodalwrap" open={i.fs} size="large">
                  <Modal.Content>
                    <Image
                      className="gallerymodalimg"
                      src={"/gallery/" + i.file}
                    />
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      color="red"
                      onClick={() => {
                        this.setState((prevState) => ({
                          images: prevState.images.map((ob) =>
                            ob.file === i.file
                              ? { ...ob, fs: false }
                              : { ...ob, fs: false }
                          ),
                        }));
                      }}
                    >
                      <Icon name="remove" /> Close
                    </Button>
                  </Modal.Actions>
                </Modal>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
