import "./Buy.css";
import ProductsList from "./ProductsList";
import MediaQuery from "react-responsive";
import React, { Component } from "react";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import Cart from "./Cart";

export default class Buy extends Component {
  products = this.props.products;
  addToCart = this.props.addToCart;
  cart = this.props.cart;
  render() {
    if (this.loading) {
      return (
        <Dimmer active>
          <Loader active />
        </Dimmer>
      );
    }
    return (
      <>
        
        
        <div className="buywrap">
          <MediaQuery minWidth="720px">
            <div className="listwraplg">
              <ProductsList
                products={this.products}
                addToCart={this.addToCart}
              />
            </div>
          </MediaQuery>
          <MediaQuery maxWidth="719px">
            <div className="listwrapsm">
              <ProductsList
                products={this.products}
                addToCart={this.addToCart}
              />
            </div>
          </MediaQuery>
        </div>
      </>
    );
  }
}
