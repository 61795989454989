import React from 'react'
import MediaQuery from 'react-responsive'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css'
import { Dropdown, List, Menu } from 'semantic-ui-react'
import './App.css'
import logo from './Components/images/EIB 3D Logo v25.png'
import { Home } from './Components/Home'
import Services from './Components/Services'
import { About } from './Components/About'
import FloatingContactBtn from './Components/FloatingContactBtn'
import Gallery from './Components/Gallery'
import Buy from './Components/Buy'

import { commerce } from './lib/commerce'
import Cart from './Components/Cart'
import CheckOut from './Components/CheckOut'
import Confirmation from './Components/Confirmation'
const Menulink = props => (
  <Dropdown.Item>
    <Link to={props.url} key={props.title}>
      {props.title}
    </Link>
  </Dropdown.Item>
)

const Menubtn = props => (
  <Menu.Item>
    <Link to={props.url} key={props.title}>
      {props.title}
    </Link>
  </Menu.Item>
)

class App extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      pages: [
        {
          title: 'Home',
          url: '/'
        },
        {
          title: 'Services',
          url: '/services'
        },
        {
          title: 'Buy',
          url: '/buy'
        },
        {
          title: 'Gallery',
          url: '/gallery'
        },
        {
          title: 'About',
          url: '/about'
        },
        {
          title: 'Cart',
          url: '/cart',
          qty: {}
        }
      ],
      products: [],
      merchant: {},
      cart: {},
      isCartVisible: true,
      order: {},
      checkoutModal: false,
      cartModal: false
    }
    this.handleUpdateCartQty = this.handleUpdateCartQty.bind(this)
    this.handleRemoveFromCart = this.handleRemoveFromCart.bind(this)
    this.handleEmptyCart = this.handleEmptyCart.bind(this)
  }
  /*buy start */
  fetchProducts () {
    commerce.products
      .list()
      .then(products => {
        this.setState({ products: products.data })
      })
      .catch(error => {
        alert('There was an error fetching the products', error)
      })
  }
  fetchCart () {
    commerce.cart
      .retrieve()
      .then(cart => {
        this.setState({ cart })
      })
      .catch(error => {
        alert('There was an error fetching the cart', error)
      })
  }
  handleAddToCart (productId, quantity) {
    commerce.cart
      .add(productId, quantity)
      .then(item => {
        this.setState({ cart: item.cart })
      })
      .catch(error => {
        alert('There was an error adding the item to the cart', error)
      })
  }
  handleUpdateCartQty (lineItemId, quantity) {
    commerce.cart
      .update(lineItemId, { quantity })
      .then(resp => {
        this.setState({ cart: resp.cart })
      })
      .catch(error => {
        alert('There was an error updating the cart items', error)
      })
  }
  handleRemoveFromCart (lineItemId) {
    commerce.cart
      .remove(lineItemId)
      .then(resp => {
        this.setState({
          cart: resp.cart
        })
      })
      .catch(error => {
        alert('There was an error removing the item from the cart', error)
      })
  }
  handleEmptyCart () {
    commerce.cart
      .empty()
      .then(resp => {
        this.setState({ cart: resp.cart })
      })
      .catch(error => {
        alert('There was an error emptying the cart', error)
      })
  }

  refreshCart () {
    commerce.cart
      .refresh()
      .then(newCart => {
        this.setState({
          cart: newCart
        })
      })
      .catch(error => {
        alert('There was an error refreshing your cart', error)
      })
  }
  handleCaptureCheckout (checkoutTokenId, newOrder) {
    commerce.checkout
      .capture(checkoutTokenId, newOrder)
      .then(order => {
        // Save the order into state
        this.setState({
          order
        })
        // Clear the cart
        this.refreshCart()
        // Send the user to the receipt
        this.props.history.push('/checkout/confirmation_thank_you')
        // Store the order in session storage so we can show it again if the
        // user refreshes the page!
        window.sessionStorage.setItem('order_receipt', JSON.stringify(order))
      })
      .catch(error => {
        alert('There was an error confirming your order', error)
      })
  }
  componentDidMount () {
    this.fetchProducts()
    this.fetchCart()
  }
  /*buy end */
  otherpaths = [
    '/services',
    '/buy',
    '/projects',
    '/gallery',
    '/checkout',
    '/cart',
    '/about'
  ]
  render () {
    const { products, loading, cart, cartModal, checkoutModal } = this.state
    if (loading) {
      return <p>Loading...</p>
    }
    return (
      <Router>
        <div className='App'>
          <div className='nav'>
            <img className='navlogo' height='100%' src={logo} alt='logo' />
            <MediaQuery minWidth='900px'>
              <div className='titlecontainer'>
                <h1 className='navtitle'>Everything in Between</h1>
                <p className='tagline'>
                  When good enough just isn't good enough...
                </p>
              </div>
            </MediaQuery>
            <Menu className='menu'>
              <MediaQuery minWidth='700px'>
                {this.state.pages.map(p => (
                  <Menubtn title={p.title} url={p.url} />
                ))}
              </MediaQuery>
              <MediaQuery maxWidth='700px'>
                <Dropdown
                  icon='list icon'
                  text='Menu'
                  floating
                  labeled
                  button
                  className='icon'
                  id='menubutton'
                >
                  <Dropdown.Menu>
                    {this.state.pages.map(p => (
                      <Menulink title={p.title} url={p.url} />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </MediaQuery>
            </Menu>
          </div>
          <div className='appbg'>
            <Switch>
              <Route path='/' exact>
                <Home />
              </Route>
              <Route path='/buy' exact>
                <Buy
                  products={products}
                  addToCart={this.handleAddToCart}
                  cart={cart}
                />
              </Route>
              <Route path='/services' exact>
                <Services />
              </Route>
              <Route path='/cart' exact>
                <Cart
                  cart={cart}
                  onUpdateCartQty={this.handleUpdateCartQty}
                  onRemoveFromCart={this.handleRemoveFromCart}
                  onEmptyCart={this.handleEmptyCart}
                  products={products}
                  cartModal={cartModal}
                  checkoutModal={checkoutModal}
                />
              </Route>

              <Route path='/gallery' exact>
                <Gallery />
              </Route>

              <Route path='/about' exact>
                <About />
              </Route>
              <Route
                path='/checkout/confirmation_thank_you'
                exact
                render={props => {
                  if (!this.state.order) {
                    return props.history.push('/')
                  }
                  return <Confirmation {...props} order={this.order} />
                }}
              />
              <Route path='/checkout' exact>
                <CheckOut
                  cart={cart}
                  checkoutModal={checkoutModal}
                  onCaptureCheckout={this.handleCaptureCheckout}
                />
              </Route>
            </Switch>
          </div>
        </div>
        <footer className='footer'>
          <Route exact path={this.otherpaths}>
            <FloatingContactBtn />
          </Route>
          <List bulleted horizontal link>
            <List.Item as='a'>Terms and Conditions</List.Item>
            <List.Item as='a'>Privacy Policy</List.Item>
            <List.Item as='a'>Contact Us</List.Item>
          </List>
        </footer>
      </Router>
    )
  }
}

export default App
