import React from "react";
import "./CheckOut.css";
import { commerce } from "../lib/commerce";

export default class CheckOut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkoutToken: {},
      firstName: "",
      lastName: "",
      email: "",
      // Shipping details
      shippingName: "",
      shippingStreet: "",
      shippingCity: "",
      shippingStateProvince: "",
      shippingPostalZipCode: "",
      shippingCountry: "",
      // Payment details
      cardNum: "",
      expMonth: "1",
      expYear: "",
      ccv: "",
      billingPostalZipcode: "",
      // Shipping and fulfillment data
      shippingCountries: {},
      shippingSubdivisions: {},
      shippingOptions: [],
      shippingOption: "",
    };
    this.handleFormChanges = this.handleFormChanges.bind(this);
  }
  fetchSubdivisions(countryCode) {
    commerce.services
      .localeListSubdivisions(countryCode)
      .then((subdivisions) => {
        this.setState({
          shippingSubdivisions: subdivisions.subdivisions,
        });
      })
      .catch((error) => {
        alert("There was an error fetching the subdivisions", error);
      });
  }
  fetchShippingCountries(checkoutTokenId) {
    commerce.services
      .localeListShippingCountries(checkoutTokenId)
      .then((countries) => {
        this.setState({
          shippingCountries: countries.countries,
        });
      })
      .catch((error) => {
        alert(
          "There was an error fetching a list of shipping countries",
          error
        );
      });
  }

  fetchShippingOptions(checkoutTokenId, country, stateProvince = null) {
    commerce.checkout
      .getShippingOptions(checkoutTokenId, {
        country: country,
        region: stateProvince,
      })
      .then((options) => {
        const shippingOption = options[0] || null;
        this.setState({
          shippingOptions: options,
          shippingOption: shippingOption,
        });
      })
      .catch((error) => {
        alert("There was an error fetching the shipping methods", error);
      });
  }
  generateCheckoutToken() {
    const { cart } = this.props;
    if (cart.line_items.length) {
      return commerce.checkout
        .generateToken(cart.id, { type: "cart" })
        .then((token) => {
          this.setState({ checkoutToken: token });
        })
        .then(() => this.fetchShippingCountries(this.state.checkoutToken.id))
        .catch((error) => {
          alert(
            "There was an error in generating a checkout token please contact us at contact@grdevs.net",
            error
          );
        });
    }
  }
  componentDidMount() {
    this.generateCheckoutToken();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.shippingCountry !== prevState.shippingCountry) {
      this.fetchShippingOptions(
        this.state.checkoutToken.id,
        this.state.shippingCountry
      );
    }
  }
  handleFormChanges(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleShippingCountryChange(e) {
    const currentValue = e.target.value;
    this.fetchSubdivisions(currentValue);
  }

  handleSubdivisionChange(e) {
    const currentValue = e.target.value;
    this.fetchShippingOptions(
      this.state.checkoutToken.id,
      this.state.shippingCountry,
      currentValue
    );
  }
  sanitizedLineItems(lineItems) {
    return lineItems.reduce((data, lineItem) => {
      const item = data;
      let variantData = null;
      if (lineItem.selected_options.length) {
        variantData = {
          [lineItem.selected_options[0].group_id]:
            lineItem.selected_options[0].option_id,
        };
      }
      item[lineItem.id] = {
        quantity: lineItem.quantity,
        variants: variantData,
      };
      return item;
    }, {});
  }
  handleCaptureCheckout(e) {
    e.preventDefault();
    const orderData = {
      line_items: this.sanitizedLineItems(this.props.cart.line_items),
      customer: {
        firstname: this.state.firstName,
        lastname: this.state.lastName,
        email: this.state.email,
      },
      shipping: {
        name: this.state.shippingName,
        street: this.state.shippingStreet,
        town_city: this.state.shippingCity,
        county_state: this.state.shippingStateProvince,
        postal_zip_code: this.state.shippingPostalZipCode,
        country: this.state.shippingCountry,
      },
      fulfillment: {
        shipping_method: this.state.shippingOption.id,
      },
      payment: {
        gateway: "test_gateway",
        card: {
          number: this.state.cardNum,
          expiry_month: this.state.expMonth,
          expiry_year: this.state.expYear,
          ccv: this.state.ccv,
          postal_zip_code: this.state.billingPostalZipcode,
        },
      },
    };
    this.props.onCaptureCheckout(this.state.checkoutToken.id, orderData);
  }
  render() {
    console.log("LINE ITEMS" + this.props.cart.line_items);
    return (
      <div className="checkoutwrap">
        <form className="checkout__form" onChange={this.handleFormChanges}>
          <h4 className="checkout__subheading">Customer information</h4>

          <label className="checkout__label" htmlFor="firstName">
            First name
          </label>
          <input
            className="checkout__input"
            type="text"
            value={this.state.firstName}
            onChange={this.handleFormChanges}
            name="firstName"
            placeholder="Enter your first name"
            required
          />

          <label className="checkout__label" htmlFor="lastName">
            Last name
          </label>
          <input
            className="checkout__input"
            type="text"
            value={this.state.lastName}
            onChange={this.handleFormChanges}
            name="lastName"
            placeholder="Enter your last name"
            required
          />

          <label className="checkout__label" htmlFor="email">
            Email
          </label>
          <input
            className="checkout__input"
            type="text"
            value={this.state.email}
            onChange={this.handleFormChanges}
            name="email"
            placeholder="Enter your email"
            required
          />

          <h4 className="checkout__subheading">Shipping details</h4>

          <label className="checkout__label" htmlFor="shippingName">
            Full name
          </label>
          <input
            className="checkout__input"
            type="text"
            value={this.state.shippingName}
            onChange={this.handleFormChanges}
            name="shippingName"
            placeholder="Enter your shipping full name"
            required
          />

          <label className="checkout__label" htmlFor="shippingStreet">
            Street address
          </label>
          <input
            className="checkout__input"
            type="text"
            value={this.state.shippingStreet}
            onChange={this.handleFormChanges}
            name="shippingStreet"
            placeholder="Enter your street address"
            required
          />

          <label className="checkout__label" htmlFor="shippingCity">
            City
          </label>
          <input
            className="checkout__input"
            type="text"
            value={this.state.shippingCity}
            onChange={this.handleFormChanges}
            name="shippingCity"
            placeholder="Enter your city"
            required
          />

          <label className="checkout__label" htmlFor="shippingPostalZipCode">
            Postal/Zip code
          </label>
          <input
            className="checkout__input"
            type="text"
            value={this.state.shippingPostalZipCode}
            onChange={this.handleFormChanges}
            name="shippingPostalZipCode"
            placeholder="Enter your postal/zip code"
            required
          />
          <label className="checkout__label" htmlFor="shippingCountry">
            Country
          </label>
          <select
            value={this.state.shippingCountry}
            onChange={this.handleShippingCountryChange}
            name="shippingCountry"
            className="checkout__select"
          >
            <option disabled selected>
              Country
            </option>
            {Object.keys(this.state.shippingCountries).map((index) => {
              return (
                <option value={index} key={index}>
                  {this.state.shippingCountries[index]}
                </option>
              );
            })}
            ;
          </select>

          <label className="checkout__label" htmlFor="shippingStateProvince">
            State/province
          </label>
          <select
            value={this.state.shippingStateProvince}
            onChange={this.handleSubdivisionChange}
            name="shippingStateProvince"
            className="checkout__select"
          >
            <option className="checkout__option" disabled selected>
              State/province
            </option>
            {Object.keys(this.state.shippingSubdivisions).map((index) => {
              return (
                <option value={index} key={index}>
                  {this.state.shippingSubdivisions[index]}
                </option>
              );
            })}
            ;
          </select>

          <label className="checkout__label" htmlFor="shippingOption">
            Shipping method
          </label>
          <select
            value={this.state.shippingOption.id}
            onChange={this.handleFormChanges}
            name="shippingOption"
            className="checkout__select"
          >
            <option className="checkout__select-option" selected disabled>
              Select a shipping method
            </option>
            {this.state.shippingOptions.map((method, index) => {
              return (
                <option
                  className="checkout__select-option"
                  value={method.id}
                  key={index}
                >{`${method.description} - $${method.price.formatted_with_code}`}</option>
              );
            })}
            ;
          </select>
          <h4 className="checkout__subheading">Payment information</h4>

          <label className="checkout__label" htmlFor="cardNum">
            Credit card number
          </label>
          <input
            className="checkout__input"
            type="text"
            name="cardNum"
            value={this.state.cardNum}
            onChange={this.handleFormChanges}
            placeholder="Enter your card number"
          />

          <label className="checkout__label" htmlFor="expMonth">
            Expiry month
          </label>
          <input
            className="checkout__input"
            type="text"
            name="expMonth"
            value={this.state.expMonth}
            onChange={this.handleFormChanges}
            placeholder="Card expiry month"
          />

          <label className="checkout__label" htmlFor="expYear">
            Expiry year
          </label>
          <input
            className="checkout__input"
            type="text"
            name="expYear"
            value={this.state.expYear}
            onChange={this.handleFormChanges}
            placeholder="Card expiry year"
          />

          <label className="checkout__label" htmlFor="ccv">
            CCV
          </label>
          <input
            className="checkout__input"
            type="text"
            name="ccv"
            value={this.state.ccv}
            onChange={this.handleFormChanges}
            placeholder="CCV (3 digits)"
          />

          <button
            onClick={this.handleCaptureCheckout}
            className="checkout__btn-confirm"
          >
            Confirm order
          </button>
        </form>
      </div>
    );
  }
}
