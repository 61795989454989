import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import "./FloatingContactBtn.css";
import ContactModal from "./Modal";

export default class FloatingContactBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: false,
    };
  }
  render() {
    return (
      <div className="contactbutton">
        <ContactModal />
      </div>
    );
  }
}
