import React from "react";
import "./ProductsList.css";
import ProductItem from "./ProductItem";

export default class ProductsList extends React.Component {
  render() {
    const { products } = this.props;
    const { addToCart } = this.props;
    return (
      <div className="products">
        {products.map((product) => (
          <ProductItem
            key={product.id}
            product={product}
            addToCart={addToCart}
          />
        ))}
      </div>
    );
  }
}
